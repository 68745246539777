import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from 'firebase/functions';
const functions = getFunctions();
process.env.NODE_ENV === 'development' &&
  connectFunctionsEmulator(functions, 'localhost', 5001);

export async function getStockAPI(
  username,
  families,
  dateParam,
  fileExtension
) {
  const data = {
    username: username,
    families: families,
    date: dateParam,
    fileExtension: fileExtension,
  };
  try {
    const getStock = httpsCallable(functions, 'stock', { timeout: 540000 });
    const response = await getStock(data).then((result) => result.data);
    return response;
  } catch {
    console.log('error');
    return false;
  }
}
