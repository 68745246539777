const firebaseConfig = {
  apiKey: "AIzaSyAOLqZt_QTz_wJqXjIVrQYvWiME5dkABiU",
  authDomain: "proveedores-32119.firebaseapp.com",
  projectId: "proveedores-32119",
  storageBucket: "proveedores-32119.appspot.com",
  messagingSenderId: "809602397575",
  appId: "1:809602397575:web:4dd9f010ab286265c80f22",
  measurementId: "G-V9FNZ4X4ZQ"
};

export default firebaseConfig;