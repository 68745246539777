import { Layout, Row, Typography } from 'antd';
const { Header } = Layout;
const { Text } = Typography;

const Head = ({ name }) => {
  return (
    <Header
      className='bg-azul-claro'
      style={{ height: '10vh', lineHeight: 'normal' }}
    >
      <Row justify='center' className='mts'>
        <Text className='font-white'>Portal de Proveedores</Text>
      </Row>
      <Row justify='center'>
        <Text className='font-white bold'>{name}</Text>
      </Row>
    </Header>
  );
};

export default Head;
