import { Col, Divider, Dropdown, Menu, message, Row, Tooltip } from 'antd';
import DatePicker from '../../widgets/datepicker';
import esES from 'antd/lib/date-picker/locale/es_ES';
import Download from '../../widgets/download';
import {
  InfoCircleOutlined,
  LoadingOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { useContext, useState } from 'react';
import { AuthContext } from '../../utils/context/authContext';
import { format } from 'date-fns';
import { getStockAPI } from '../../data/stockAPI';

esES.lang.locale = 'es';

const Stock = ({ mobile }) => {
  const dateFormat = 'DD-MM-YYYY';
  const { user } = useContext(AuthContext);
  const [document, setDocument] = useState('');
  const [generateLoading, setGenerateLoading] = useState(false);
  const [dateParam, setDateParam] = useState(format(new Date(), 'yyyyMMdd'));
  const [disabled, setDisabled] = useState(false);

  async function handleStockRequest(e) {
    let validation = validateDate();
    if (!validation) return;
    setGenerateLoading(true);
    setDocument('');
    const stock = await getStockAPI(user.name, user.families, dateParam, e.key);
    if (!stock) {
      message.error(
        'El archivo no pudo ser generado, revisa tu conexión o intenta con fechas más acotadas.',
        2
      );
      setGenerateLoading(false);
      return;
    }
    setDocument(stock);
    setGenerateLoading(false);
  }

  function handleDatePicked(e) {
    const date = e ? format(e, 'yyyyMMdd') : null;
    setDateParam(date);
    date == null ? setDisabled(true) : setDisabled(false);
  }

  function validateDate() {
    if (dateParam) {
      return true;
    }
    message.error('Debes seleccionar una fecha');
    return false;
  }

  const typesMenu = (
    <Menu onClick={(e) => handleStockRequest(e)}>
      <Menu.Item key='xls'>Excel</Menu.Item>
      <Menu.Item key='csv'>CSV</Menu.Item>
      <Menu.Item key='txt'>Text</Menu.Item>
    </Menu>
  );

  return (
    <Col span={24} className={mobile ? 'mm' : 'mxl'}>
      <Row className='mtl' align='middle'>
        <Col span={mobile ? 24 : 12}>
          <DatePicker
            placeholder='Escoja una fecha para consultar stock'
            locale={esES}
            defaultValue={new Date()}
            onChange={handleDatePicked}
            format={dateFormat}
            size='large'
            style={{
              width: '100%',
              color: 'rgba(0, 0, 0, 0.65)',
              borderRadius: '5px',
            }}
          />
        </Col>
        <Col
          span={mobile ? 24 : 8}
          offset={mobile ? 0 : 4}
          className={mobile ? 'mtm' : ''}
        >
          <Row justify={mobile ? 'center' : 'end'} align='middle'>
            <Col span={4}>
              <Tooltip
                title='Entre mayor sea el rango de tiempo, mayor será el tiempo de carga del documento'
                className='prs'
              >
                <InfoCircleOutlined />
              </Tooltip>
            </Col>
            <Col span={20}>
              <Dropdown.Button
                className='pxs'
                type='primary'
                icon={
                  generateLoading ? <LoadingOutlined /> : <DownloadOutlined />
                }
                overlay={generateLoading ? '' : typesMenu}
                disabled={disabled}
                style={{
                  minHeight: '40px',
                  whiteSpace: 'normal',
                  paddingTop: '0px',
                }}
              >
                {generateLoading ? 'Consultando' : 'Consultar'}
              </Dropdown.Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className='mtl'>
        <Divider />
      </Row>
      {document !== '' ? (
        <Row justify='center' className='mtxl'>
          <Download file={document} />
        </Row>
      ) : null}
    </Col>
  );
};

export default Stock;
