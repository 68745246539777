import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  createUserWithEmailAndPassword,
  updateProfile,
} from 'firebase/auth';

import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';

import { auth, db } from '../utils/firebase/firebase';

import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from 'firebase/functions';
const functions = getFunctions();

process.env.NODE_ENV === 'development' &&
  connectFunctionsEmulator(functions, 'localhost', 5001);

export async function signInAPI(email, password) {
  const logInResponse = await signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      return userCredential;
    })
    .catch((error) => {
      console.log('Error on login:', error.code);
      console.log('Error on login:', error.message);
      return null;
    });
  return logInResponse;
}

export async function logOutAPI() {
  await signOut(auth)
    .then(() => {
      window.localStorage.setItem('auth', false);
      return true;
    })
    .catch((error) => {
      console.log('Error: ', error);
      return false;
    });
}

export async function sendPasswordRecoveryAPI(email) {
  auth.languageCode = 'es';
  let recoveryResponse = await sendPasswordResetEmail(auth, email)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log(error.code);
      console.log(error.message);
      return false;
    });
  return recoveryResponse;
}

export async function createUserAPI(email, password) {
  let creationStatus = await createUserWithEmailAndPassword(
    auth,
    email,
    password
  )
    .then((userCredential) => {
      return userCredential.user;
    })
    .catch((error) => {
      console.log(error.code);
      console.log(error.message);
      console.log(error.name);
      return error.code;
    });
  return creationStatus;
}

export async function updateUserInfo(name, rut) {
  const user = auth.currentUser;
  let response;
  if (user) {
    response = await updateProfile(auth.currentUser, {
      displayName: name,
      photoURL: rut,
    })
      .then(() => true)
      .catch((error) => {
        console.log(error);
        return false;
      });
  } else {
    response = false;
  }

  return response ? user : false;
}

export async function setUserInfo(uid, name, families) {
  try {
    await addDoc(collection(db, 'users'), {
      uid: uid,
      name: name,
      families: families,
    });
    return true;
  } catch (e) {
    console.error('Error adding document: ', e);
    return false;
  }
}

export async function getUserInfo(uid) {
  const q = query(collection(db, 'users'), where('uid', '==', `${uid}`));
  const document = await getDocs(q)
    .then((data) => data.docs)
    .catch((error) => error);
  if (document.length !== 0) return document[0].data();
  return false;
}

export async function isSuperuser(username, password) {
  const data = { username: username, password: password };
  try {
    const getSuperUser = httpsCallable(functions, 'superuser');
    const response = await getSuperUser(data).then((result) => result.data);
    return response;
  } catch {
    console.log('error');
    return false;
  }
}
