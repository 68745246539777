import { onAuthStateChanged } from '@firebase/auth';
import { createContext, useState } from 'react';
import {
  signInAPI,
  logOutAPI,
  sendPasswordRecoveryAPI,
  createUserAPI,
  updateUserInfo,
  setUserInfo,
  getUserInfo,
} from '../../data/authAPI';
import { auth } from '../firebase/firebase';

export const AuthContext = createContext();

const AuthProvider = (props) => {
  const [user, setUser] = useState({});

  async function checkUser() {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const uid = user.uid;
        const userInfo = await getUserInfo(uid);
        if (userInfo) setUser(userInfo);
      } else {
        console.log('loggedOut');
      }
    });
  }

  async function LogIn(email, password) {
    const response = await signInAPI(email, password);
    if (response) {
      const userInfo = await getUserInfo(response.user.uid);
      if (userInfo) {
        setUser(userInfo);
        window.localStorage.setItem('auth', true);
        return true;
      }
    }
    return false;
  }

  async function LogOut() {
    await logOutAPI();
  }

  async function sendRecovery(email) {
    let response = await sendPasswordRecoveryAPI(email);
    return response;
  }

  async function createUser(email, password, name, families) {
    let creationResponse = await createUserAPI(email, password);
    if (creationResponse.uid) {
      let response = await setUserInfo(creationResponse.uid, name, families);
      return response;
    }
    return creationResponse;
  }

  async function updateProfile(name, rut) {
    let response = await updateUserInfo(name, rut);
    if (response) setUser(response);
    return response;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        checkUser,
        LogIn,
        LogOut,
        sendRecovery,
        createUser,
        updateProfile,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
