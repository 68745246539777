import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from 'firebase/functions';
const functions = getFunctions();

process.env.NODE_ENV === 'development' &&
  connectFunctionsEmulator(functions, 'localhost', 5001);

export async function getFamiliesAPI() {
  try {
    const getFamilies = httpsCallable(functions, 'families');
    const response = await getFamilies().then((result) => result.data);
    return response;
  } catch {
    console.log('error');
    return false;
  }
}
