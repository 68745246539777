import { Button } from 'antd';

const Download = ({ file }) => {
  function handleDownload() {
    var downloadLink = document.createElement('a');
    downloadLink.href = file;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  return (
    <Button
      type='primary'
      style={{
        minHeight: '40px',
        whiteSpace: 'normal',
        paddingTop: '0px',
      }}
      onClick={() => handleDownload()}
    >
      Descargar Documento
    </Button>
  );
};

export default Download;
