import { Col, Divider, Dropdown, Menu, message, Row, Tooltip } from 'antd';
import DatePicker from '../../widgets/datepicker';
import esES from 'antd/lib/date-picker/locale/es_ES';
import {
  InfoCircleOutlined,
  DownloadOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import Download from '../../widgets/download';
import { useContext, useState } from 'react';
import { AuthContext } from '../../utils/context/authContext';
import { getSalesAPI } from '../../data/salesAPI';
import subMonths from 'date-fns/subMonths';
import { format } from 'date-fns';

esES.lang.locale = 'es';

const { RangePicker } = DatePicker;
const Sales = ({ mobile }) => {
  const dateFormat = 'DD-MM-YYYY';
  const { user } = useContext(AuthContext);
  const [document, setDocument] = useState('');
  const [generateLoading, setGenerateLoading] = useState(false);
  const [dateParam, setDateParam] = useState([
    format(subMonths(new Date(), 1), 'yyyyMMdd'),
    format(new Date(), 'yyyyMMdd'),
  ]);
  const [disabled, setDisabled] = useState(false);

  function handleDatePicked(e) {
    let dates = e ? [format(e[0], 'yyyyMMdd'), format(e[1], 'yyyyMMdd')] : null;
    setDateParam(dates);
    dates == null ? setDisabled(true) : setDisabled(false);
  }

  async function handleSales(e) {
    setGenerateLoading(true);
    setDocument('');
    const sales_url = await getSalesAPI(
      user.name,
      user.families,
      dateParam[0],
      dateParam[1],
      e.key
    );
    if (!sales_url) {
      message.error(
        'El archivo no pudo ser generado, revisa tu conexión o intenta con fechas más acotadas.',
        2
      );
      setGenerateLoading(false);
      return;
    }
    setDocument(sales_url);
    setGenerateLoading(false);
  }

  const typesMenu = (
    <Menu onClick={(e) => handleSales(e)}>
      <Menu.Item key='xls'>Excel</Menu.Item>
      <Menu.Item key='csv'>CSV</Menu.Item>
      <Menu.Item key='txt'>Text</Menu.Item>
    </Menu>
  );

  return (
    <Col span={24} className={mobile ? 'mm' : 'mxl'}>
      <Row className='mtl' align='middle'>
        <Col span={mobile ? 24 : 12}>
          <RangePicker
            placeholder={['Desde', 'hasta']}
            locale={esES}
            defaultValue={[subMonths(new Date(), 1), new Date()]}
            onChange={handleDatePicked}
            format={dateFormat}
            size='large'
            style={{
              width: '100%',
              color: 'rgba(0, 0, 0, 0.65)',
              borderRadius: '5px',
            }}
          />
        </Col>
        <Col
          span={mobile ? 24 : 8}
          offset={mobile ? 0 : 4}
          className={mobile ? 'mtm' : ''}
        >
          <Row justify={mobile ? 'center' : 'end'} align='middle'>
            <Col span={4}>
              <Tooltip
                title='Entre mayor sea el rango de tiempo, mayor será el tiempo de carga del documento'
                className='prs'
              >
                <InfoCircleOutlined />
              </Tooltip>
            </Col>
            <Col span={20}>
              <Dropdown.Button
                className='pxs'
                type='primary'
                icon={
                  generateLoading ? <LoadingOutlined /> : <DownloadOutlined />
                }
                overlay={generateLoading ? '' : typesMenu}
                disabled={disabled}
                style={{
                  minHeight: '40px',
                  whiteSpace: 'normal',
                  paddingTop: '0px',
                }}
              >
                {generateLoading ? 'Consultando' : 'Consultar'}
              </Dropdown.Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className='mtl'>
        <Divider />
      </Row>
      {document !== '' ? (
        <Row justify='center' className='mtxl'>
          <Download file={document} />
        </Row>
      ) : null}
    </Col>
  );
};

export default Sales;
