import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Typography,
  Form,
  Image,
  message,
  Select,
  Spin,
  Modal,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import logoAxam from '../../assets/logo.svg';
import { isSuperuser } from '../../data/authAPI';
import { getFamiliesAPI } from '../../data/extraInfoManagerAPI';
import { AuthContext } from '../../utils/context/authContext';

const { Text } = Typography;
const { Option } = Select;

const SignUp = () => {
  const [form] = useForm();
  const [formSuperuser] = useForm();
  const navigate = useNavigate();
  const { createUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [optionsLoading, setOptionsLoading] = useState(true);
  const [families, setFamilies] = useState([]);
  const [visible, setVisible] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);

  useEffect(() => {
    initState();
  }, []);

  async function initState() {
    setOptionsLoading(true);
    const families = await getFamiliesAPI();
    setFamilies(families);
    setOptionsLoading(false);
  }

  const options = families.map((family) => (
    <Option value={family.name} key={family.code}>
      {family.name}
    </Option>
  ));

  async function confirmSuperuser() {
    let isSuperuserResponse = await formSuperuser
      .validateFields()
      .then(async (values) => {
        setModalLoading(true);
        let superuser = await isSuperuser(values.username, values.password);
        setModalLoading(false);
        return superuser;
      });
    if (!isSuperuserResponse) {
      message.error('usuario inválido', 2);
      return;
    }
    setVisible(false);
  }

  async function handleCreate() {
    await form
      .validateFields()
      .then(async (values) => {
        setLoading(true);
        let authResponse = await createUser(
          values.email,
          values.password,
          values.name,
          values.familias
        );
        setLoading(false);
        if (authResponse === 'auth/email-already-in-use') {
          message.error('Este mail se encuentra inscrito', 2);
          return;
        } else if (authResponse === true) {
          navigate('/login');
          return;
        }
        message.error('No se pudo crear al usuario, favor intentar denuevo');
      })
      .catch((error) => console.log(error));
  }

  return (
    <Col span={24}>
      <Row justify='center' align='middle' style={{ height: '100vh' }}>
        <Col span={6}>
          <Card bordered={false} bodyStyle={{ padding: '0px' }}>
            <Row justify='center'>
              <Image src={logoAxam} preview={false} />
            </Row>
            <Row justify='center mbl'>
              <Text className='fm font-azul'>Proveedores</Text>
            </Row>
            {optionsLoading ? (
              <Row justify='center'>
                <Spin size='large' tip='loading data' />
              </Row>
            ) : (
              <Form
                form={form}
                name='basic'
                initialValues={{ remember: true }}
                wrapperCol={{ span: 22, offset: 1 }}
                autoComplete='off'
              >
                <Form.Item
                  className='mbm'
                  name='name'
                  rules={[
                    {
                      required: true,
                      message: 'Ingrese el nombre de la Empresa',
                    },
                  ]}
                >
                  <Input className='grey rounded' placeholder='Empresa' />
                </Form.Item>
                <Form.Item
                  className='mbs'
                  name='email'
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: 'Ingrese su email',
                      type: 'email',
                    },
                  ]}
                >
                  <Input
                    className='grey rounded'
                    placeholder='Email'
                    type='email'
                  />
                </Form.Item>

                <Form.Item
                  className='mbm'
                  name='password'
                  rules={[
                    {
                      required: true,
                      validator: (_, value) =>
                        value.length >= 6
                          ? Promise.resolve()
                          : Promise.reject(new Error('Minimo 6 carácteres')),
                    },
                  ]}
                >
                  <Input.Password
                    className='grey rounded'
                    placeholder='contraseña'
                  />
                </Form.Item>

                <Form.Item
                  name='familias'
                  rules={[
                    {
                      required: true,
                      message: 'Selecciona las familias de este usuario',
                      type: 'array',
                    },
                  ]}
                >
                  <Select mode='multiple' placeholder='Selecciona las familias'>
                    {options}
                  </Select>
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button
                    type='primary'
                    htmlType='submit'
                    className='rounded bold'
                    loading={loading}
                    onClick={() => {
                      handleCreate();
                    }}
                  >
                    Crear Usuario
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Card>
        </Col>
      </Row>
      <Modal
        title='Credenciales de superuser'
        visible={visible}
        maskClosable={false}
        closable={false}
        footer={null}
        maskStyle={{ backgroundColor: 'white' }}
      >
        <Form
          form={formSuperuser}
          name='superUser'
          initialValues={{ remember: true }}
          wrapperCol={{ span: 22, offset: 1 }}
          autoComplete='off'
        >
          <Form.Item
            className='mbs'
            name='username'
            required={true}
            rules={[{ required: true, message: 'Ingrese su usuario' }]}
          >
            <Input className='grey rounded' />
          </Form.Item>

          <Form.Item
            className='mbm'
            name='password'
            rules={[{ required: true, message: 'ingrese su contraseña' }]}
          >
            <Input.Password className='grey rounded' />
          </Form.Item>

          <Form.Item>
            <Row justify='center'>
              <Button
                type='primary'
                htmlType='submit'
                className='rounded bold'
                loading={modalLoading}
                onClick={() => {
                  confirmSuperuser();
                }}
              >
                Ingresar
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </Col>
  );
};
export default SignUp;
