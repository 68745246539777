import { Image, Layout, Menu, Row } from 'antd';
import {
  PieChartOutlined,
  ReconciliationOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import logoBlanco from '../assets/logoBlanco.svg';
import { Link, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../utils/context/authContext';
const { Sider } = Layout;

const Sidebar = () => {
  const { LogOut } = useContext(AuthContext);

  let location = useLocation();

  async function handleLogout() {
    await LogOut();
  }

  return (
    <Sider collapsible={false} width='20vw' className='bg-azul'>
      <Row justify='center' className='mbxl mtxl'>
        <Image src={logoBlanco} preview={false} width='80%' />
      </Row>
      <Menu
        selectedKeys={location.pathname}
        mode='inline'
        className='bg-azul no-border'
      >
        <Menu.Item
          key='/auth/sales'
          icon={<PieChartOutlined className='font-white bold' />}
        >
          <Link to='sales' className='font-white bold'>
            Informe de Ventas
          </Link>
        </Menu.Item>
        <Menu.Item
          key='/auth/stock'
          icon={<ReconciliationOutlined className='font-white bold' />}
        >
          <Link to='stock' className='font-white bold'>
            Stock
          </Link>
        </Menu.Item>
        {/* <Menu.Item
          key='/profile'
          icon={<UserOutlined className='font-white bold' />}
        >
          <Link to='profile' className='font-white bold'>
            Profile
          </Link>
        </Menu.Item> */}
        <Menu.Item
          key='/logout'
          icon={<LogoutOutlined className='font-white bold' />}
        >
          <Link
            to='/login'
            className='font-white bold'
            onClick={() => handleLogout()}
          >
            Cerrar Sesión
          </Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
