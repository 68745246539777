import { Navigate } from 'react-router-dom';

function PrivateRoute({ children }) {
  const authed = window.localStorage.getItem('auth');
  const ele = authed === 'true' ? children : <Navigate to='/login' />;

  return ele;
}

export default PrivateRoute;
