import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Typography,
  Form,
  Image,
  message,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import logoAxam from '../../assets/logo.svg';
import { AuthContext } from '../../utils/context/authContext';

const { Text } = Typography;

const Recovery = () => {
  const [form] = useForm();
  const navigate = useNavigate();
  const { sendRecovery } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  async function handleRecovery() {
    setLoading(true);
    await form
      .validateFields()
      .then(async (values) => {
        let recoveryResponse = await sendRecovery(values.username);
        setLoading(false);
        if (recoveryResponse) {
          message.success(
            'Email de recuperación enviado, por favor siga las instrucciones',
            2
          );
          navigate('/login');
          return;
        }
        message.error('Email no asociado a AXAM Proveedores');
      })
      .catch((error) => console.log(error));
  }

  return (
    <Col span={24}>
      <Row justify='center' align='middle' style={{ height: '100vh' }}>
        <Col span={6}>
          <Card bordered={false} bodyStyle={{ padding: '0px' }}>
            <Row justify='center'>
              <Image src={logoAxam} preview={false} />
            </Row>
            <Row justify='center mbl'>
              <Text className='fm font-azul'>Proveedores</Text>
            </Row>
            <Form
              form={form}
              name='basic'
              initialValues={{ remember: true }}
              wrapperCol={{ span: 22, offset: 1 }}
              autoComplete='off'
            >
              <Form.Item
                className='mbxl'
                name='username'
                required={true}
                rules={[{ required: true, message: 'Ingrese su usuario' }]}
              >
                <Input className='grey rounded' placeholder='Email' />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                <Button
                  type='primary'
                  htmlType='submit'
                  className='rounded bold mtm'
                  loading={loading}
                  onClick={() => {
                    handleRecovery();
                  }}
                >
                  Recuperar Contraseña
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

export default Recovery;
