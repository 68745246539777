import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Typography,
  Form,
  Image,
  message,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import logoAxam from '../../assets/logo.svg';
import { AuthContext } from '../../utils/context/authContext';

const { Text, Link } = Typography;

const Login = () => {
  const [form] = useForm();
  const navigate = useNavigate();
  const { LogIn, LogOut } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  useEffect(
    () => LogOut(),
    // eslint-disable-next-line
    []
  );

  async function handleLogin() {
    setLoading(true);
    await form
      .validateFields()
      .then(async (values) => {
        let authResponse = await LogIn(values.username, values.password);
        setLoading(false);
        if (authResponse) {
          navigate('/auth/');
          return;
        }
        message.error('Usuario o clave inválida');
      })
      .catch((error) => console.log(error));
  }

  return (
    <Col span={24}>
      <Row justify='center' align='middle' style={{ height: '100vh' }}>
        <Col span={6}>
          <Card bordered={false} bodyStyle={{ padding: '0px' }}>
            <Row justify='center'>
              <Image src={logoAxam} preview={false} />
            </Row>
            <Row justify='center mbl'>
              <Text className='fm font-azul'>Proveedores</Text>
            </Row>
            <Form
              form={form}
              name='basic'
              initialValues={{ remember: true }}
              wrapperCol={{ span: 22, offset: 1 }}
              autoComplete='off'
            >
              <Form.Item
                className='mbs'
                name='username'
                required={true}
                rules={[{ required: true, message: 'Ingrese su usuario' }]}
              >
                <Input className='grey rounded' />
              </Form.Item>

              <Form.Item
                className='mbm'
                name='password'
                rules={[{ required: true, message: 'ingrese su contraseña' }]}
              >
                <Input.Password className='grey rounded' />
              </Form.Item>

              <Form.Item>
                <Row justify='center'>
                  <Button
                    type='primary'
                    htmlType='submit'
                    className='rounded bold'
                    loading={loading}
                    onClick={() => {
                      handleLogin();
                    }}
                  >
                    Ingresar
                  </Button>
                </Row>
              </Form.Item>
            </Form>
            <Row justify='center'>
              <Link
                className='fxs'
                underline
                onClick={() => navigate('/recovery')}
              >
                Recuperar Contraseña
              </Link>
            </Row>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

export default Login;
