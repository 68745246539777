import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import axios from 'axios';
const functions = getFunctions();

process.env.NODE_ENV === 'development' &&
  connectFunctionsEmulator(functions, 'localhost', 5001);

axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';

export async function getSalesAPI(
  username,
  families,
  initDate,
  endDate,
  fileExtension
) {
  const data = {
    username: username,
    families: families,
    initDate: initDate,
    endDate: endDate,
    fileExtension: fileExtension,
  };
  console.log(data);
  let url;
  process.env.NODE_ENV === 'development'
    ? (url = 'http://localhost:8080/sales')
    : (url = 'https://sales-npx2tsr4uq-uc.a.run.app/sales');
  try {
    const response = await axios
      .post(url, data, {
        timeout: 540000,
        maxBodyLength: Infinity,
        maxContentLength: Infinity,
      })
      .then((res) => res.data);
    return response;
  } catch (e) {
    console.log('error', e);
    return false;
  }
}
