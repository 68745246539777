import Login from './modules/login/login';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import Main from './modules/main';
import AuthProvider from './utils/context/authContext';
import PrivateRoute from './utils/privateRoute';
import Recovery from './modules/login/recovery';
import SignUp from './modules/login/createUser';
import { useEffect, useState } from 'react';

function App() {
  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;
    width < 500 ? setMobile(true) : setMobile(false);
  };

  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path='login' element={<Login />} />
          <Route path='recovery' element={<Recovery />} />
          <Route path='create' element={<SignUp />} />
          <Route
            path='/auth/*'
            element={
              <PrivateRoute>
                <Main mobile={mobile} />
              </PrivateRoute>
            }
          />
          <Route path='*' element={<Navigate to='/auth/sales' />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
