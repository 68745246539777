import { Layout, Row, Spin } from 'antd';
import { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { AuthContext } from '../utils/context/authContext';
import Head from '../widgets/header';
import Sidebar from '../widgets/sidebar';
// import Profile from './profile/profile';
import Sales from './sales/sales';
import Stock from './stock/stock';

const { Content } = Layout;

const Main = ({ mobile }) => {
  const { user, checkUser } = useContext(AuthContext);

  useEffect(() => {
    checkUser();
    // eslint-disable-next-line
  }, []);
  const registeredRoutes = (
    <Routes>
      <Route path='sales' element={<Sales mobile={mobile} />} />
      <Route path='stock' element={<Stock mobile={mobile} />} />
      {/* <Route path='profile' element={<Profile />} /> */}
      <Route path='*' element={<Navigate to='sales' />} />
    </Routes>
  );

  return (
    <Layout style={{ height: '100vh' }}>
      <Sidebar />
      <Layout>
        <Head name={user.name} />
        <Content className='ms'>
          {Object.keys(user).length === 0 ? (
            <Row justify='center' align='middle' style={{ height: '100%' }}>
              <Spin size='large'></Spin>
            </Row>
          ) : (
            registeredRoutes
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

export default Main;
