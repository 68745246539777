// Import the functions you need from the SDKs you need

// import { getAnalytics } from "firebase/analytics";
import firebaseConfig from './config';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const db = getFirestore(app);

// const analytics = getAnalytics(app);
